import { Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LanguageService } from './modules/language/services/language.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './services/cart.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter, startWith, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AccountApi } from './api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) protected platformId: any,
        private router: Router,
        private zone: NgZone,
        private language: LanguageService,
        private toastr: ToastrService,
        private cart: CartService,
        private translate: TranslateService,
        private localizeRouterService: LocalizeRouterService,
        public account: AccountApi,
    ) {
        localizeRouterService.hooks.initialized.subscribe(value => {
            if (value && isPlatformBrowser(platformId)) {
                this.setLanguage();
            }
        });
    }

    setLanguage() {
        const cachedLang = localStorage.getItem('plang');
        if (cachedLang && cachedLang != this.localizeRouterService.parser.currentLang
            && this.language.all.find(value => value.code === cachedLang)) {
            this.localizeRouterService.changeLanguage(cachedLang);
        }
    }

    ngOnInit(): void {

        if (isPlatformBrowser(this.platformId)) {

            this.zone.runOutsideAngular(() => {
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd),
                    startWith(this.router),
                ).subscribe(() => {
                    /**
                     * отправка активности после завершения навигации куда-либо
                     */
                    this.account.sendLastActivity().pipe(take(1)).subscribe();

                    const preloader = document.querySelector('.site-preloader');

                    if (!preloader) {
                        return;
                    }

                    preloader.addEventListener('transitionend', (event: Event) => {
                        if (event instanceof TransitionEvent && event.propertyName === 'opacity') {
                            preloader.remove();
                            document.querySelector('.site-preloader-style')?.remove();
                        }
                    });
                    preloader.classList.add('site-preloader__fade');

                    // Sometimes, due to unexpected behavior, the browser (in particular Safari) may not play the transition, which leads
                    // to blocking interaction with page elements due to the fact that the preloader is not deleted.
                    // The following block covers this case.
                    if (getComputedStyle(preloader).opacity === '0' && preloader.parentNode) {
                        preloader.parentNode.removeChild(preloader);
                    }
                });
            });
        }

        this.cart.onAdding$.subscribe(product => {
            this.toastr.success(
                this.translate.instant('TEXT_TOAST_PRODUCT_ADDED_TO_CART', { productName: product.name }),
            );
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
