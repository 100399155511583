import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ShopCategory } from '../../interfaces/category';
import { Bom, Product, ProductReport, ProductRequest, ProductWait } from '../../interfaces/product';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import {
    GetCategoriesOptions,
    GetCategoryBySlugOptions,
    GetProductReviewsOptions,
    GetProductsListOptions,
    GetSearchSuggestionsOptions,
    GetSearchSuggestionsResult,
    ShopApi,
} from '../base';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LanguageService } from '../../modules/language/services/language.service';
import { BaseVehicle, GroupVehicle, ModificationVehicle } from '../../interfaces/vehicle';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class RestShopApi extends ShopApi {

    url = environment.apiHost;

    // private idFromSlug(slug: string) {
    //     return slug.split('-')[0];
    // }

    constructor(
        private http: HttpClient,
        private language: LanguageService,
    ) {
        super();
    }

    private get lang(): string {
        return `lan=${this.language.current.code}`;
    }

    private get lan(): string {
        return this.language.current.code;
    }

    getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        let params = new HttpParams().set('slug', slug);

        if(options?.depth) {
            params = params.set('depth', options.depth);
        }

        const url = `${this.url}/product/category?${this.lang}`;
        return this.http.get<ShopCategory[]>(url, { params: params }).pipe(
            map(categories => categories[0]),
        );
    }

    getCategories(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        let url = `${this.url}/product/category?${this.lang}`;
        let params = new HttpParams()
        if (options?.slugs && options?.slugs.length) {
            params = params.append('slug', options.slugs[0]);
        }
        if (options?.depth) {
            params = params.append('depth', options.depth);
        }
        if (options?.withParent !== undefined) {
            params = params.append('with-parents', options.withParent);
        }
        return this.http.get<ShopCategory[]>(url, { params: params });
    }

    getProductsList(options?: GetProductsListOptions): Observable<ProductsList> {
        let url = `${this.url}/product/products?${this.lang}`;
        if (options?.targetKeyword) {
            url = `${url}&vehicle=${options.targetKeyword}`;
        }
        if (options?.category) {
            url = `${url}&category=${options.category}`;
        }
        if (options?.page) {
            url = `${url}&page=${options.page}`;
        }
        if (options?.limit) {
            url = `${url}&size=${options.limit}`;
        } else {
            url = `${url}&size=16`;
        }
        if (options?.sort) {
            url = `${url}&sort=${options.sort}`;
        }
        if (options?.keyword) {
            url = `${url}&keyword=${options?.keyword}`
        }
        if (options?.filters?.['constructive']) {
            const slugs = (options.filters['constructive'] as string).split(',');
            if (slugs) {
                slugs.forEach((item) => {
                    url = `${url}&constructives[]=${item}`;
                });
            }
        }
        if (options?.brand) {
            url = `${url}&brand=${options.brand}`;
        }
        if (options?.filters?.['brand']) {
            const brands = (options.filters['brand'] as string).split(',');
            if (brands) {
                brands.forEach((item) => {
                    url = `${url}&brands[]=${item}`;
                });
            }
        }
        if (options?.carBrand) {
            url = `${url}&car-brand=${options.carBrand}`;
        }
        if (options?.carModel) {
            url = `${url}&car-model=${options.carModel}`;
        }
        if (options?.filters?.['price']) {
            const prices = (options.filters['price'] as string).split('-');
            if (prices) {
                url = `${url}&from_price=${prices[0]}`;
                url = `${url}&to_price=${prices[1]}`;
            }
        }
        for (const filter in options?.filters) {
            if (filter.includes('property-range')) {
                const regex = /property-range_(.+)/;
                const match = filter.match(regex);
                if (match) {
                    const id = match[1];
                    const value = options!.filters[filter];
                    const values = value.split('-').map((e) => {
                        return e === 'NaN' ? '' : e;
                    });
                    if (values) {
                        url = `${url}&property-range[${id}][from]=${values[0]}`;
                        url = `${url}&property-range[${id}][to]=${values[1]}`;
                    }
                }
            } else if (filter.includes('property')) {
                const regex = /property_(.+)/;
                const match = filter.match(regex);
                if (match) {
                    const id = match[1];
                    const value = options!.filters[filter];
                    if (value) {
                        url = `${url}&property[${id}][]=${value}`;
                    }
                }
            }
        }
        return this.http.get<ProductsList>(url);
    }

    getProductBySlug(slug: string, dummy: boolean): Observable<Product> {
        // return getProductBySlug(slug);
        // const productId = slug.split('-')[0];
        const url = `${this.url}/product/product?${this.lang}`;
        let params = new HttpParams().set('slug', slug);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product>(url, { params: params });
    }

    getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
        // return getProductReviews(productId, options);
        const url = `${this.url}/product/review?${this.lang}&id=${productId}`;
        return this.http.get<ReviewsList>(url);
    }

    getProductAnalogs(productId: number, dummy: boolean): Observable<Product[]> {
        const url = `${this.url}/product/analogs?${this.lang}`;
        let params = new HttpParams().set('id', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product[]>(url, { params });
    }

    getProductReplaces(productId: number, dummy: boolean): Observable<Product[]> {
        const url = `${this.url}/product/replaces?${this.lang}`;
        let params = new HttpParams().set('id', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<Product[]>(url, { params });
    }

    getLatestProducts(limit: number): Observable<Product[]> {
        let url = `${this.url}/product/top-novelty`;
        const params = new HttpParams()
            .set('lan', this.lan)
            .set('limit', limit)
        return this.http.get<Product[]>(url, { params: params });
    }

    getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
        let url = `${this.url}/product/search`;

        // let vehicle = this.garage.currentVehicle ? this.garage.currentVehicle.targetKeyword : null;

        let params = new HttpParams()
            .set('lan', this.lan)
            .set('term', query)
            .set('product_limit', options?.limitProducts!)
            .set('categories_limit', options?.limitCategories!);

        // if (vehicle) {
        //     params = params.set('vehicle', vehicle);
        // }

        return this.http.get<GetSearchSuggestionsResult>(url, { params: params });
    }

    addToWaitList(productId: number): Observable<any> {
        const url = `${this.url}/product/add-wait-list`;
        const body = {
            id: productId,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(body), { headers: headers });
    }

    removeFromWaitList(productId: number): Observable<any> {
        const url = `${this.url}/product/remove-wait-list`;
        const body = {
            id: productId,
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(body), { headers: headers });
    }

    getWaitList(onlyAvailable?: boolean): Observable<ProductWait[]> {
        const url = `${this.url}/product/load-wait-list`;
        let params = new HttpParams();
        if (onlyAvailable) {
            params = new HttpParams()
                .set('onlyAvailable', onlyAvailable)
        }
        return this.http.get<ProductWait[]>(url, { params: params });
    }

    clearWaitList(): Observable<any> {
        const url = `${this.url}/product/clear-wait-list`;
        return this.http.get(url);
    }

    getBom(articleId: number, productCode?: string): Observable<Bom[]> {
        const url = `${this.url}/product/bom`;
        let params = new HttpParams()
            .set('article_id', articleId);

        if (productCode) {
            params = params.append('product_code', productCode);
        }
        return this.http.get<Bom[]>(url, { params });
    }

    getApplicability(productId: number): Observable<ModificationVehicle[]> {
        const url = `${this.url}/product/applicability?id=${productId}`;
        return this.http.get<ModificationVehicle[]>(url);
    }

    productReport(report: ProductReport): Observable<any> {
        const url = `${this.url}/product/report`;
        const params = new HttpParams().set('product_id', report.productId);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(report), { headers: headers, params: params });
    }

    getApplicabilityBrands(productId: number, dummy: boolean): Observable<BaseVehicle[]> {
        const url = `${this.url}/product/applicability-brands`;
        let params = new HttpParams()
            .set('productId', productId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<BaseVehicle[]>(url, { params: params });
    }

    getApplicabilityModels(productId: number, brandId: number, dummy: boolean): Observable<GroupVehicle[]> {
        const url = `${this.url}/product/applicability-models`;
        let params = new HttpParams()
            .set('productId', productId)
            .set('brandId', brandId);
        if (dummy) {
            params = params.append('crossDummy', dummy);
        }
        return this.http.get<GroupVehicle[]>(url, { params: params });
    }

    productRequest(request: ProductRequest): Observable<any> {
        const url = `${this.url}/product/request`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, JSON.stringify(request), { headers });
    }
}
