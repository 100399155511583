import { Observable } from 'rxjs';
import { ShopCategory } from '../../interfaces/category';
import { Bom, Product, ProductReport, ProductRequest, ProductWait } from '../../interfaces/product';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import { BaseVehicle, GroupVehicle, ModificationVehicle } from '../../interfaces/vehicle';
import { Brand } from '../../interfaces/brand';

export interface GetCategoryBySlugOptions {
    depth?: number;
}

export interface GetCategoriesOptions {
    parent?: Partial<ShopCategory>;
    slugs?: string[];
    depth?: number;
    withParent?: number,
}

export interface GetBrandsOptions {
    limit?: number;
}

export interface GetProductsListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    brand?: string;
    category?: string;
    keyword?: string;
    carBrand?: string;
    carModel?: string;
    targetKeyword?: string;
    filters?: {[slug: string]: string};
}

export interface GetProductReviewsOptions {
    page?: number;
    limit?: number;
    sort?: string;
    filters?: {[slug: string]: string};
}

export interface AddProductReviewData {
    rating: number;
    author: string;
    email: string;
    content: string;
}

export interface GetSearchSuggestionsOptions {
    limitProducts?: number;
    limitCategories?: number;
}

export interface GetSearchSuggestionsResult {
    products: Product[];
    categories: ShopCategory[];
}

export abstract class ShopApi {
    abstract getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory>;

    abstract getCategories(options?: GetCategoriesOptions): Observable<ShopCategory[]>;

    abstract getProductsList(options?: GetProductsListOptions, ): Observable<ProductsList>;

    abstract getProductBySlug(slug: string, dummy: boolean): Observable<Product>;

    abstract getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList>;

    abstract getProductAnalogs(productId: number, dummy: boolean): Observable<Product[]>;

    abstract getProductReplaces(productId: number, dummy: boolean): Observable<Product[]>;

    abstract getLatestProducts(limit: number): Observable<Product[]>;

    abstract getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult>;

    abstract addToWaitList(productId: number): Observable<any>;

    abstract removeFromWaitList(productId: number): Observable<any>;

    abstract clearWaitList(): Observable<any>;

    abstract getWaitList(onlyAvailable?: boolean): Observable<ProductWait[]>;

    abstract getBom(articleId: number, productCode?: string): Observable<Bom[]>;

    abstract getApplicability(productId: number): Observable<ModificationVehicle[]>;

    abstract getApplicabilityBrands(productId: number, dummy: boolean): Observable<BaseVehicle[]>;

    abstract getApplicabilityModels(productId: number, brandId: number, dummy: boolean): Observable<GroupVehicle[]>;

    abstract productReport(report: ProductReport): Observable<any>;

    abstract productRequest(request: ProductRequest): Observable<any>;
}
